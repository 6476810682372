/* AboutMe.css */
.about-me-container {
  padding-top: 6rem;
  position: relative;
  z-index: 2;
}

.box-shadow-full {
  background: rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(15px);
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 20px;
  padding: 2.5rem;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.card {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.03)) !important;
  backdrop-filter: blur(12px);
  border: 1px solid rgba(255, 255, 255, 0.12) !important;
  margin-bottom: 1.2rem;
  border-radius: 15px !important;
  color: #ffffff;
  transition: all 0.3s ease;
  padding: 1.2rem;
}

.card:hover {
  transform: translateX(5px);
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05)) !important;
  border-color: rgba(255, 165, 0, 0.4) !important;
  box-shadow: 0 5px 15px rgba(255, 165, 0, 0.15);
}

.card-title {
  color: #ffffff;
  font-weight: 700;
  font-size: 1.2rem;
  margin-bottom: 0.8rem;
  background: linear-gradient(90deg, #ffffff, #ffa500);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card-text {
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 0.6rem;
  font-size: 0.95rem;
  line-height: 1.6;
}

.list-group-item {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.03)) !important;
  backdrop-filter: blur(12px);
  border: 1px solid rgba(255, 255, 255, 0.12) !important;
  margin-bottom: 1.2rem;
  border-radius: 15px !important;
  color: #ffffff;
  transition: all 0.3s ease;
  padding: 1.2rem;
}

.list-group-item:hover {
  transform: translateX(5px);
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05)) !important;
  border-color: rgba(255, 165, 0, 0.4) !important;
  box-shadow: 0 5px 15px rgba(255, 165, 0, 0.15);
}

.list-group-item h4 {
  color: #ffffff;
  font-weight: 700;
  font-size: 1.2rem;
  margin-bottom: 0.8rem;
  background: linear-gradient(90deg, #ffffff, #ffa500);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.list-group-item p {
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 0.6rem;
  font-size: 0.95rem;
}

.title-left {
  font-weight: 800;
  position: relative;
  margin-bottom: 2.5rem;
  padding-bottom: 0.5rem;
}

.title-left::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50px;
  height: 3px;
  background: linear-gradient(90deg, #ffa500, rgba(255, 165, 0, 0.3));
  border-radius: 2px;
}

.fade-in {
  animation: fadeIn 1.2s cubic-bezier(0.215, 0.610, 0.355, 1.000);
}

@keyframes fadeIn {
  0% { 
    opacity: 0; 
    transform: translateY(30px); 
  }
  100% { 
    opacity: 1; 
    transform: translateY(0); 
  }
}

#about {
  position: relative;
  z-index: 2;
  color: #ffffff;
  min-height: 100vh;
}
