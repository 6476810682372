.article-detail {
  padding: 4rem 0;
  background: #fff;
}

.back-link {
  display: inline-block;
  margin-bottom: 2rem;
  color: #666;
  text-decoration: none;
  transition: color 0.3s ease;
}

.back-link:hover {
  color: #00b0ff;
}

.back-link i {
  margin-right: 0.5rem;
}

.article-header {
  margin-top: 10px;
  padding-top: 10px;
  position: static;
}

.article-title {
  margin-bottom: 2rem;
  font-size: 3rem;
  font-weight: 800;
  line-height: 1.3;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  letter-spacing: -0.5px;
  background: linear-gradient(
    45deg,
    #ffffff 0%,
    #f0f0f0 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
}

/* Add specific override for h1 */
h1.article-title {
  margin-top: 15px !important;
  padding-top: 20px !important;
  position: static !important;
}

.article-meta {
  margin-bottom: 2rem;
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.article-meta > span {
  margin-right: 1.5rem;
}

.article-meta i {
  margin-right: 0.5rem;
  color: #00b0ff;
}

.article-image {
  margin-bottom: 2rem;
}

.article-image img {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.article-content {
  position: relative;
  margin-top: 2rem;
}

.article-content img {
  max-width: 100%;
  height: auto;
  margin: 2rem 0;
  border-radius: 4px;
}

.article-footer {
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: 1px solid #eee;
}

.article-loading,
.article-error {
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.article-error {
  color: #dc3545;
}

.article-detail-wrapper {
  margin-top: 120px;
  padding-top: 200px;
  position: relative;
  z-index: 1;
}

.article-content-wrapper {
  padding-top: 150px;
  position: relative;
  z-index: 2;
}

.article-title-container {
  margin-top: 200px;
  padding-top: 200px;
  position: relative;
  z-index: 3;
}

/* Add specific styles to override any potential conflicts */
.navbar-b.navbar-reduce {
  z-index: 100;
}

.article-section {
  min-height: 100vh;
  position: relative;
  z-index: 2;
  color: #ffffff;
}

.article-title {
  margin-bottom: 2rem;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.3;
  color: #ffffff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.article-meta {
  margin-bottom: 2rem;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
}

.article-content {
  background: rgba(255, 255, 255, 0.12);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 2rem;
  margin: 2rem 0;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.article-content img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  margin: 1.5rem 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.article-content p {
  color: rgba(255, 255, 255, 0.95);
  line-height: 1.8;
  font-size: 1.1rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
}

.article-content h1, 
.article-content h2, 
.article-content h3, 
.article-content h4 {
  color: #ffffff;
  margin-top: 2rem;
  font-weight: 600;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.article-content a {
  color: #00b0ff;
  text-decoration: none;
  font-weight: 500;
}

.article-content a:hover {
  text-decoration: underline;
  color: #66d4ff;
}

.article-content code {
  background: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  padding: 0.2em 0.4em;
  border-radius: 4px;
  font-size: 0.9em;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.article-content pre {
  background: rgba(0, 0, 0, 0.7) !important;
  padding: 1.5rem;
  border-radius: 10px;
  overflow-x: auto;
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin: 1.5rem 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  color: #ffffff;
}

.article-content pre code {
  color: #ffffff !important;
  background: transparent !important;
  padding: 0;
  font-family: 'Consolas', 'Monaco', monospace;
  font-size: 0.95rem;
  line-height: 1.6;
}

/* Make lists more visible */
.article-content ul,
.article-content ol {
  color: rgba(255, 255, 255, 0.95);
  margin-bottom: 1.5rem;
  padding-left: 1.5rem;
}

.article-content li {
  margin-bottom: 0.5rem;
}

/* Make blockquotes stand out */
.article-content blockquote {
  border-left: 4px solid #00b0ff;
  padding-left: 1rem;
  margin-left: 0;
  color: rgba(255, 255, 255, 0.9);
  font-style: italic;
}

/* Alert styles */
.alert {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #ffffff;
}

.alert-danger {
  background: rgba(220, 53, 69, 0.2);
  border-color: rgba(220, 53, 69, 0.3);
}

.alert-warning {
  background: rgba(255, 193, 7, 0.2);
  border-color: rgba(255, 193, 7, 0.3);
}

/* Style command blocks specifically */
.article-content .command-block,
.article-content [class*="language-"] {
  background: rgba(0, 0, 0, 0.7) !important;
  color: #ffffff !important;
  padding: 1rem;
  border-radius: 8px;
  margin: 1rem 0;
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-family: 'Consolas', 'Monaco', monospace;
}

/* Ensure all code-related elements are visible */
.article-content *[class*="highlight"],
.article-content *[class*="code"],
.article-content *[class*="command"] {
  color: #ffffff !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

@media (max-width: 768px) {
  .container {
    padding-top: 4rem !important;
  }
  
  h1 {
    font-size: 2rem !important;
  }
} 

/* Code block wrapper */
.code-block-wrapper {
  position: relative;
  margin: 1.5rem 0;
}

/* Copy button styles */
.copy-button-wrapper {
  position: absolute;
  top: 0.35rem;
  right: 0.35rem;
  z-index: 10;
}

.copy-button {
  background: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 3px;
  color: #ffffff;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  font-weight: 500;
  letter-spacing: 0.2px;
  min-height: 24px;
}

.copy-button:hover {
  background: rgba(255, 255, 255, 0.25);
  border-color: rgba(255, 255, 255, 0.4);
}

.copy-button.copied {
  background: rgba(72, 187, 120, 0.2);
  border-color: rgba(72, 187, 120, 0.4);
}

/* Make sure code blocks have enough room for the button */
.article-content pre {
  padding-right: 4.5rem;
}

/* Animation for copy success */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-2px); }
  to { opacity: 1; transform: translateY(0); }
}

.copy-button i {
  animation: fadeIn 0.2s ease;
  font-size: 0.8rem;
}

.read-more-btn {
  display: block;
  width: fit-content;
  margin: -2rem auto 2rem;
  background-color: transparent;
  border: 1.5px solid #fff;
  color: #fff;
  padding: 6px 16px;
  border-radius: 20px;
  text-decoration: none;
  transition: all 0.3s ease;
  font-size: 0.9rem;
  position: relative;
  z-index: 2;
}

.read-more-btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: translateY(-2px);
  color: #fff;
} 