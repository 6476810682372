.dark-theme-page {
  min-height: 100vh;
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: #1a1a1a;
  color: #ffffff;
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0.85) 0%,
    rgba(0, 32, 63, 0.80) 30%,
    rgba(0, 64, 128, 0.75) 70%,
    rgba(0, 0, 0, 0.85) 100%
  );
  z-index: 1;
}

.content-wrapper {
  position: relative;
  z-index: 2;
  padding: 2rem;
} 