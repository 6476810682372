.about-mf {
  padding-top: 4rem;
  position: relative;
  z-index: 2;
  min-height: 100vh;
}

.title-left {
  text-align: center;
  margin-bottom: 2rem;
  font-weight: 700;
  position: relative;
  padding: 1rem;
  width: 100%;
}

.box-shadow-full {
  background: rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 2rem;
  margin-bottom: 3rem;
}

/* Timeline customization */
.vertical-timeline-element--work {
  margin: 2rem 0;
}

.vertical-timeline-element-content {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
}

.vertical-timeline-element-content h3,
.vertical-timeline-element-content h4 {
  color: #fff;
}

.vertical-timeline-element-date {
  color: #00b0ff !important;
  opacity: 1 !important;
}

/* Container styling */
.container h2 {
  color: #f27500;
  font-size: 1.3rem;
  margin-top: 1.5rem;
}

.container ul {
  list-style-type: none;
  padding-left: 0;
}

.container ul li {
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 0.5rem;
  padding-left: 1.2rem;
  position: relative;
}

.container ul li:before {
  content: "•";
  color: #00b0ff;
  position: absolute;
  left: 0;
}

.container p {
  color: rgba(255, 255, 255, 0.8);
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .about-mf {
    padding-top: 2rem;
  }

  .title-left {
    margin: 1rem auto 2rem;
    font-size: 1.5rem;
  }

  .box-shadow-full {
    padding: 1rem;
  }
}

/* Mobile Card View Styles */
.experience-cards {
  padding: 10px;
}

.experience-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 1.2rem;
  margin-bottom: 1.5rem;
}

.experience-card h3 {
  color: #fff;
  font-size: 1.2rem;
  margin-bottom: 0.3rem;
}

.experience-card h4 {
  color: #f27500;
  font-size: 1rem;
  margin-bottom: 0.3rem;
}

.experience-card .date {
  color: #00b0ff;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.experience-card .card-content {
  margin-top: 1rem;
}

.experience-card .card-content h2 {
  color: #f27500;
  font-size: 1.1rem;
  margin-bottom: 0.8rem;
}

.experience-card .card-content h3 {
  font-size: 1rem;
  margin-top: 0.8rem;
}

.experience-card .card-content ul {
  list-style-type: none;
  padding-left: 1rem;
}

.experience-card .card-content ul li {
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 0.4rem;
  position: relative;
}

.experience-card .card-content ul li:before {
  content: "•";
  color: #00b0ff;
  position: absolute;
  left: -1rem;
}

/* Hide timeline on mobile and cards on desktop */
@media screen and (max-width: 768px) {
  .vertical-timeline {
    display: none !important;
  }
}

@media screen and (min-width: 769px) {
  .experience-cards {
    display: none;
  }
}
