.footer {
  width: 100%;
  background: rgba(0, 21, 41, 0.95);
  backdrop-filter: blur(8px);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 1rem 0;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  color: #ffffff;
  font-size: 0.9rem;
}

.footer-links {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.footer-links a {
  color: #ffffff;
  text-decoration: none;
  transition: opacity 0.3s ease;
}

.footer-links a:hover {
  opacity: 0.8;
}

.separator {
  margin: 0 8px;
  color: #666;
}

.footer-text a {
  color: inherit;
  text-decoration: none;
  font-weight: 500;
}

.footer-text a:hover {
  text-decoration: underline;
}

.footer-text .heart {
  color: #e25555;
  font-weight: bold;
  font-size: 1.2rem;
}

/* Responsive design */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }
} 