.portfolio-section {
  padding-top: 4rem;
  position: relative;
  z-index: 2;
  min-height: 100vh;
}

/* Simplified title styling without box */
.title-left {
  text-align: center;
  margin-bottom: 2rem;
  font-weight: 700;
  position: relative;
  padding: 1rem;
  width: 100%;
}

/* Projects container */
.box-shadow-full {
  margin-top: 1rem;
  background: rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 2rem;
}

/* Remove the old title styles */
.title-box,
.subtitle-a,
.line-mf {
  display: none;
}

/* Portfolio items styling */
.work-box {
  background: rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  margin-bottom: 3rem;
  transition: all 0.3s ease;
}

.work-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.work-img {
  border-radius: 15px 15px 0 0;
  overflow: hidden;
}

.work-img img {
  width: 100%;
  transition: all 0.6s ease;
}

.work-img img:hover {
  transform: scale(1.1);
}

.work-content {
  padding: 1.5rem;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 0 0 15px 15px;
}

.w-title {
  font-size: 1.2rem;
  color: #ffffff;
  margin-bottom: 0.5rem;
}

.w-more {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem;
}

.w-ctegory {
  color: #00b0ff;
}

.w-like {
  font-size: 1.2rem;
  color: #00b0ff;
  float: right;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .portfolio-section {
    padding-top: 2rem;
  }

  .title-left {
    margin: 1rem auto 2rem;
    font-size: 1.5rem;
  }

  .box-shadow-full {
    padding: 1rem;
  }

  .work-box {
    margin-bottom: 2rem;
  }
}

/* iOS safe area support */
@supports (-webkit-touch-callout: none) {
  .portfolio-section {
    padding-top: calc(2rem + env(safe-area-inset-top));
  }
}

/* Image lightbox customization */
.lb-data .lb-caption {
  color: #ffffff;
  font-size: 1.1rem;
}

.lb-data .lb-details {
  background: rgba(0, 0, 0, 0.8);
  padding: 1rem;
  border-radius: 0 0 10px 10px;
} 