.testimonials-section {
  padding: 2rem 0;
}

.testimonials-grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 300px));
  justify-content: center;
  margin: 0 auto;
  max-width: 1200px;
}

.testimonial-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  transition: transform 0.2s ease;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

.testimonial-card:hover {
  transform: translateY(-2px);
}

.testimonial-content {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.testimonial-text {
  color: #e0e0e0;
  font-size: 0.9rem;
  line-height: 1.4;
  margin: 0;
  font-style: italic;
}

.testimonial-author {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 0.5rem;
  margin-top: 0.5rem;
}

.testimonial-author strong {
  color: orange;
  font-size: 0.85rem;
}

.position {
  color: #cccccc;
  font-size: 0.75rem;
}

.linkedin-section {
  text-align: center;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 1.5rem;
}

.linkedin-btn {
  background-color: #0077b5;
  border: none;
  padding: 0.5rem 1.25rem;
  font-size: 0.85rem;
  transition: all 0.3s ease;
}

.linkedin-btn:hover {
  background-color: #005e93;
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .testimonials-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 280px));
    padding: 0 1rem;
  }
  
  .testimonial-card {
    margin: 0.25rem auto;
  }
}

.linkedin-screenshot {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Slider custom styles */
.slick-prev,
.slick-next {
  z-index: 1;
  width: 40px;
  height: 40px;
}

.slick-prev {
  left: -50px;
}

.slick-next {
  right: -50px;
}

.slick-prev:before,
.slick-next:before {
  font-size: 40px;
  color: orange;
}

.slick-dots li button:before {
  color: orange;
}

.slick-dots li.slick-active button:before {
  color: orange;
}

/* Custom arrow styles for better visibility */
.slick-prev:before,
.slick-next:before {
  text-shadow: 0 0 10px rgba(0,0,0,0.5);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .testimonial-box {
    margin: 0 1rem;
  }
  
  .slick-prev,
  .slick-next {
    display: none !important;
  }
}

.testimonial-quote {
  font-style: italic;
  margin-bottom: 1.5rem;
  color: #e0e0e0;
  border-left: 4px solid orange;
  padding-left: 1rem;
}

.testimonial-footer {
  color: #cccccc;
}

.testimonial-footer strong {
  color: orange;
}

.card {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
}

.card-title {
  color: orange;
}

.card-body {
  color: #ffffff;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .testimonials-section {
    padding: 2rem 0;
  }
  
  .card {
    margin: 1rem;
  }
}

.linkedin-custom-btn {
  position: relative;
  padding: 12px 30px;
  background: linear-gradient(to right, #0077B5, #00A0DC);
  border: none;
  border-radius: 50px;
  color: white;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 119, 181, 0.2);
}

.linkedin-custom-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 119, 181, 0.3);
}

.linkedin-custom-btn:active {
  transform: translateY(0);
}

.linkedin-icon {
  margin-right: 10px;
  font-size: 1.2rem;
}

.linkedin-text {
  position: relative;
  z-index: 1;
}

.linkedin-custom-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #00A0DC, #0077B5);
  transition: left 0.3s ease;
}

.linkedin-custom-btn:hover::before {
  left: 0;
} 