.policy-container {
  min-height: 100vh;
  padding: 2rem 1rem 6rem 1rem;
  background: rgba(0, 21, 41, 0.95);
  color: #ffffff;
}

.policy-content {
  max-width: 800px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 12px;
  backdrop-filter: blur(8px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.policy-content h1 {
  text-align: center;
  margin-bottom: 1.5rem;
  color: #ffffff;
}

.policy-content .last-updated {
  text-align: center;
  color: #888;
  margin-bottom: 2rem;
}

.policy-content section {
  margin-bottom: 2rem;
}

.policy-content h2 {
  color: #4a9eff;
  margin-bottom: 1rem;
}

.policy-content p {
  line-height: 1.6;
  margin-bottom: 1rem;
}

.policy-content ul {
  list-style-type: disc;
  margin-left: 2rem;
  margin-bottom: 1rem;
}

.policy-content li {
  margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
  .policy-content {
    padding: 1.5rem;
  }
}

/* ... rest of the CSS ... */ 