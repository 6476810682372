.blog-mf {
  padding-top: 4rem;
  position: relative;
  z-index: 2;
  min-height: 100vh;
}

.title-left {
  text-align: center;
  margin-bottom: 0.5rem;
  font-weight: 700;
  position: relative;
  padding: 1rem;
  width: 100%;
}

.subtitle-a {
  text-align: center;
  color: #00b0ff !important;
  margin-bottom: 2rem;
  font-size: 1.1rem;
  opacity: 1;
  display: block;
  font-weight: 500;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.blog-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  color: #ffffff;
}

.blog-error {
  text-align: center;
  color: #dc3545;
  padding: 2rem;
}

.blog-card {
  margin-bottom: 2rem;
  background: rgba(255, 255, 255, 0.05) !important;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
  transition: all 0.3s ease;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.blog-card:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.1) !important;
  border-color: rgba(0, 176, 255, 0.5);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.card-img {
  height: 200px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
}

.card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-img img[src$="iconLoading.png"] {
  width: 15px !important;
  height: 15px !important;
  min-width: 15px !important;
  min-height: 15px !important;
  max-width: 15px !important;
  max-height: 15px !important;
  padding: 0 !important;
  margin: auto !important;
  object-fit: contain !important;
  flex-shrink: 0;
}

.card-img img[src$="iconLoading.png"]:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease;
}

.card-body {
  padding: 1.5rem;
  background: transparent !important;
}

.card-title {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  line-height: 1.4;
  color: #ffffff !important;
}

.card-title a {
  color: #333;
  text-decoration: none;
  transition: color 0.3s ease;
}

.card-title a:hover {
  color: #00b0ff;
}

.card-description {
  color: rgba(255, 255, 255, 0.8) !important;
  font-size: 0.9rem;
  line-height: 1.6;
}

.card-footer {
  padding: 1rem 1.5rem;
  background: transparent !important;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.85rem;
}

.post-date {
  color: rgba(255, 255, 255, 0.6) !important;
}

.post-author {
  color: rgba(255, 255, 255, 0.6) !important;
}

.post-author i {
  margin-right: 0.5rem;
}

@media (max-width: 768px) {
  .col-md-4 {
    margin-bottom: 2rem;
  }
}

.article-detail {
  padding: 4rem 0;
  background: #fff;
  color: #ffffff;
}

.article-header {
  margin-bottom: 2rem;
}

.article-title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #ffffff;
}

.article-meta {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
  margin-bottom: 2rem;
  display: flex;
  gap: 1.5rem;
}

.article-image {
  margin-bottom: 2rem;
}

.article-image img {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.article-content {
  font-size: 1.1rem;
  line-height: 1.8;
  color: rgba(255, 255, 255, 0.9);
}

.article-content img {
  max-width: 100%;
  height: auto;
  margin: 2rem 0;
  border-radius: 4px;
}

.article-footer {
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: 1px solid #eee;
}

.card-title span {
  cursor: pointer;
  color: #ffffff !important;
}

.card-title span:hover {
  color: #00b0ff !important;
}

.back-to-list {
  transition: all 0.3s ease;
}

.back-to-list:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

/* Animations */
.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.fadeIn {
  animation-name: fadeIn;
}

.fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, 100%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  to {
    opacity: 0;
    transform: translate(-50%, 100%);
  }
}

/* Ensure text colors are visible */
.title-box .title-a {
  color: orange !important;
}

.title-box .subtitle-a {
  color: rgba(255, 255, 255, 0.8) !important;
}

.card-title {
  color: #ffffff !important;
}

.card-description {
  color: rgba(255, 255, 255, 0.8) !important;
}

.post-date, .post-author {
  color: rgba(255, 255, 255, 0.6) !important;
}

/* Make sure the line is visible */
.line-mf {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Ensure container is clickable */
.container {
  position: relative;
  z-index: 2;
}

.card-category-box {
  background: transparent !important;
}

.error-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.error-popup-content {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
  width: 90%;
}

.error-popup-content h3 {
  color: #333;
  margin-bottom: 1rem;
}

.error-popup-content p {
  color: #666;
  margin-bottom: 1.5rem;
}

.error-popup-content button {
  margin: 0.5rem;
  min-width: 200px;
}
