.back-home {
  position: fixed;
  width: 40px;
  height: 40px;
  left: 15px;
  bottom: 15px;
  background-color: #0078ff !important;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  cursor: pointer;
  transition: all 0.4s ease;
}

.home-icon {
  position: relative;
  width: 16px;
  height: 16px;
}

.roof {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid white;
}

.base {
  position: absolute;
  bottom: 0;
  left: 2px;
  width: 12px;
  height: 8px;
  background-color: white;
}

.back-home:hover {
  background-color: #298eff !important;
  transform: translateY(-3px);
}

/* Animations */
.back-home.fadeIn {
  animation: fadeIn 0.3s ease-in-out;
  display: block;
}

.back-home.fadeOut {
  animation: fadeOut 0.3s ease-in-out;
}

@keyframes fadeIn {
  0% { opacity: 0; transform: translateY(20px); }
  100% { opacity: 1; transform: translateY(0); }
}

@keyframes fadeOut {
  0% { opacity: 1; transform: translateY(0); }
  100% { opacity: 0; transform: translateY(20px); }
}

@media (max-width: 768px) {
  .back-home {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 16px;
  }
  
  .back-home i {
    line-height: 35px;
  }
} 