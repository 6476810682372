:root {
  --navbar-gradient: linear-gradient(
    135deg, 
    rgba(0,0,0,0.97) 0%,
    rgba(0,32,63,0.95) 30%,
    rgba(0,64,128,0.92) 70%,
    rgba(0,0,0,0.97) 100%
  );
}

.navbar {
  transition: all 0.3s ease;
}

/* Remove the media query restriction for the gradient background */
.navbar.navbar-reduce,
.navbar.show-mobile-menu,
.navbar.navbar-trans {
  background: var(--navbar-gradient) !important;
}

.navbar .nav-item .nav-link {
  color: #fff !important;
}

.navbar .nav-item .nav-link:hover {
  color: #00b0ff !important;
}

@media (max-width: 768px) {
  .navbar {
    background: none !important;
    padding: 0 !important;
    top: 0 !important;
    margin-top: 0 !important;
    height: 60px !important;
  }

  .navbar .container {
    background: var(--navbar-gradient) !important;
    padding: 0 20px !important;
    height: 60px !important;
    display: flex;
    align-items: center;
  }

  .navbar .navbar-brand {
    padding: 0 !important;
  }

  .navbar .navbar-brand img {
    max-height: 35px !important;
    width: auto;
  }

  .navbar .navbar-toggler {
    padding: 0 !important;
    margin: 0 !important;
    border: none !important;
  }

  .navbar .navbar-toggler span {
    width: 25px;
    height: 2px;
    margin: 4px 0;
    display: block;
    background-color: #ffffff !important;
    transition: all 0.3s ease;
  }

  .navbar-toggler:hover span {
    background-color: #00b0ff !important;
  }

  .navbar.navbar-trans,
  .navbar.show-mobile-menu,
  .navbar.navbar-reduce {
    background: linear-gradient(
      135deg, 
      rgba(0,0,0,0.97) 0%,
      rgba(0,32,63,0.95) 30%,
      rgba(0,64,128,0.92) 70%,
      rgba(0,0,0,0.97) 100%
    ) !important;
  }

  .navbar.navbar-trans .nav-item .nav-link,
  .navbar.show-mobile-menu .nav-item .nav-link,
  .navbar.navbar-reduce .nav-item .nav-link {
    color: #fff !important;
  }

  .navbar .navbar-collapse {
    background: linear-gradient(
      135deg, 
      rgba(0,0,0,0.97) 0%,
      rgba(0,32,63,0.95) 30%,
      rgba(0,64,128,0.92) 70%,
      rgba(0,0,0,0.97) 100%
    ) !important;
    margin-top: 5px;
    padding: 5px;
    border-radius: 0 0 10px 10px;
  }
}

@media (min-width: 769px) {
  /* Update desktop styles */
  .navbar {
    padding: 20px 0;
  }

  .navbar.navbar-trans,
  .navbar.navbar-reduce {
    background: var(--navbar-gradient) !important;
  }

  .navbar.navbar-trans .nav-item .nav-link,
  .navbar.navbar-reduce .nav-item .nav-link {
    color: #fff !important;
  }

  .navbar.navbar-reduce .nav-item .nav-link:hover {
    color: #00b0ff !important;
  }
}

/* iOS specific fixes */
@supports (-webkit-touch-callout: none) {
  @media (max-width: 768px) {
    .navbar {
      background: linear-gradient(
        135deg, 
        rgba(0,0,0,0.97) 0%,
        rgba(0,32,63,0.95) 30%,
        rgba(0,64,128,0.92) 70%,
        rgba(0,0,0,0.97) 100%
      ) !important;
      padding-top: env(safe-area-inset-top) !important;
    }

    .navbar .container {
      background: none !important;
    }
  }
}

@media (max-width: 768px) {
  body::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: env(safe-area-inset-top);
    background: linear-gradient(
      135deg, 
      rgba(0,0,0,0.97) 0%,
      rgba(0,32,63,0.95) 30%,
      rgba(0,64,128,0.92) 70%,
      rgba(0,0,0,0.97) 100%
    );
    z-index: 1030;
  }
}

body {
  padding-top: 0 !important;
}

#mainNav {
  padding-top: 0 !important;
}

.navbar .nav-link {
  color: rgba(255, 255, 255, 0.7);
  transition: all 0.3s ease;
  position: relative;
}

.navbar .nav-link:hover {
  color: #00b0ff;
}

.navbar .nav-link.active {
  color: #00b0ff;
}

.navbar .nav-link.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #00b0ff;
  transform: scaleX(1);
  transition: transform 0.3s ease;
}

.navbar .nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #00b0ff;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.navbar .nav-link:hover::after {
  transform: scaleX(1);
}