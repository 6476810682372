/* Remove any custom cursor styles */
* {
  cursor: default !important; /* Force default cursor everywhere */
}

/* Fix scrolling and layout issues */
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  min-height: 100vh;
}

.intro {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 90px;
  box-sizing: border-box;
  opacity: 0;
  transition: opacity 0.3s ease-in;
  z-index: 1;
  /* Remove overflow-y: auto */
  /* Remove -webkit-overflow-scrolling */
}

/* Ensure content takes up available space */
.intro-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  padding: 0 20px;
}

/* Update footer positioning */
.footer {
  position: relative; /* Changed from fixed */
  width: 100%;
  background: rgba(0, 0, 0, 0.95);
  padding: 10px 0;
  z-index: 3;
  margin-top: auto; /* Push footer to bottom */
}

/* Remove any other cursor-related styles */
.action-card,
.social-link,
a,
button {
  cursor: pointer !important; /* Keep pointer cursor for interactive elements */
}

/* Fix any duplicate scroll containers */
.intro-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* Remove any height/scroll properties */
}

body, 
.intro, 
#home, 
.intro-content {
  background-color: #001529;
}

.intro {
  background-color: #001529;
  min-height: 100vh;
  position: relative;
  margin-bottom: 40px;
  padding-top: 90px;
  box-sizing: border-box;
  opacity: 0; /* Start with opacity 0 */
  transition: opacity 0.3s ease-in; /* Smooth transition */
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px; /* Space for footer */

  /* Gradient overlay */
  .gradient-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      135deg,
      rgba(0, 21, 41, 0.97) 0%,
      rgba(0, 21, 41, 0.95) 30%,
      rgba(0, 21, 41, 0.92) 70%,
      rgba(0, 21, 41, 0.97) 100%
    );
    z-index: 1;
    opacity: 1;
  }

  /* Floating shapes container */
  .floating-shapes {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;

    .shape {
      position: absolute;
      background: linear-gradient(45deg, rgba(0, 176, 255, 0.1), rgba(0, 224, 255, 0.05));
      backdrop-filter: blur(5px);
      border: 1px solid rgba(255, 255, 255, 0.1);
      animation: float 20s infinite;

      &-1 {
        width: 400px;
        height: 400px;
        top: -200px;
        left: -100px;
        border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
        transform-origin: center;
        animation: morphShape1 15s infinite;
      }

      &-2 {
        width: 300px;
        height: 300px;
        top: 40%;
        right: -150px;
        border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
        animation: morphShape2 18s infinite;
        animation-delay: -5s;
      }

      &-3 {
        width: 250px;
        height: 250px;
        bottom: -100px;
        left: 40%;
        border-radius: 50% 50% 30% 70% / 50% 50% 70% 30%;
        animation: morphShape3 20s infinite;
        animation-delay: -10s;
      }
    }
  }

  .status-badge {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    padding: 6px 12px;
    border-radius: 30px;
    color: white;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    margin: 5px auto;
    width: fit-content;
  }

  .action-cards {
    display: flex;
    gap: 8px;
    margin: 5px 0;
    padding: 0;
    perspective: 1000px;
  }

  .action-card {
    width: 130px;
    height: 130px;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    transform-style: preserve-3d;
    transform: translateZ(0);
    will-change: transform;
    padding: 8px;

    &:hover {
      transform: translateZ(30px) scale(1.1);
      background: rgba(255, 255, 255, 0.15);
      border-color: rgba(0, 176, 255, 0.5);
      box-shadow: 
        0 10px 30px rgba(0, 0, 0, 0.3),
        0 0 20px rgba(0, 176, 255, 0.3);

      .card-icon {
        transform: translateZ(20px) scale(1.2);
        color: #00b0ff;
        text-shadow: 0 0 15px rgba(0, 176, 255, 0.5);
      }

      span {
        transform: translateZ(15px);
        text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
      }
    }

    .card-icon {
      font-size: 2.5rem;
      margin-bottom: 1rem;
      color: rgba(255, 255, 255, 0.9);
      transition: all 0.4s ease;
    }

    span {
      font-size: 1.2rem;
      font-weight: 600;
      color: #ffffff;
      text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
      transition: all 0.4s ease;
      background: transparent;
      -webkit-background-clip: initial;
      -webkit-text-fill-color: initial;
    }
  }

  .social-section {
    margin-top: auto;
    padding-top: 40px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }

  .social-links {
    display: flex;
    gap: 8px;
    margin: 5px 0;
  }

  .social-link .ico-circle {
    width: 35px;
    height: 35px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    color: white;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }

  .social-link:hover .ico-circle {
    background: rgba(0, 176, 255, 0.2);
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 176, 255, 0.3);
  }

  /* Specific icon colors on hover */
  .social-link:hover .ion-social-github {
    color: #ffffff;
  }

  .social-link:hover .ion-social-linkedin {
    color: #ffffff;
  }

  .social-link:hover .ion-social-youtube {
    color: #ffffff;
  }

  .social-link:hover .ion-social-medium {
    color: #ffffff;
  }

  .intro-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 180px);
    padding: 0 20px 80px;
    position: relative;
    z-index: 2;
  }

  .table-cell {
    width: 100%;
  }

  .intro-body {
    display: flex;
    flex-direction: column;
    gap: 5px;
    min-height: calc(100vh - 80px);
    padding-bottom: 10px;
  }

  .typed-text,
  .expertise-text,
  .language-text {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    font-weight: 600;
    letter-spacing: 0.5px;
    position: relative;
    
    &::before {
      content: '';
      position: absolute;
      top: -10px;
      left: -10px;
      right: -10px;
      bottom: -10px;
      background: rgba(0, 0, 0, 0.3);
      filter: blur(10px);
      z-index: -1;
      border-radius: 10px;
    }
  }

  .typed-text {
    font-size: 2.5rem;
    color: #ffffff;
    text-shadow: 
      2px 2px 4px rgba(0, 0, 0, 0.5),
      0 0 20px rgba(0, 176, 255, 0.3);
  }

  .expertise-text {
    font-size: 2rem;
    color: #f0f0f0;
  }

  .language-text {
    font-size: 1.8rem;
    color: #e0e0e0;
  }
}

.text-container {
  padding: 20px;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  margin: 20px 0;
}

.scroll-indicator {
  position: relative;
  z-index: 10;
  margin-top: 20px;
  opacity: 0.8;
}

.bottom-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  padding-top: 40px;
}

.bottom-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: auto;
  padding-top: 40px;
  padding-bottom: 20px;
}

.status-badge {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  padding: 6px 12px;
  border-radius: 30px;
  color: white;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  margin: 5px auto;
  width: fit-content;
  margin-bottom: 20px;
}

.social-links {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin: 5px 0;
}

.social-link .ico-circle {
  width: 35px;
  height: 35px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: white;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .bottom-content {
    padding-top: 30px;
    padding-bottom: 15px;
  }

  .status-badge {
    font-size: 0.8rem;
    padding: 6px 12px;
  }

  .intro {
    padding-top: 70px;
    padding-bottom: 30px;
    .intro-content {
      min-height: calc(100vh - 120px);
      padding: 0 10px;
      
      .intro-body {
        gap: 5px;
      }
    }

    .typed-container,
    .expertise-container,
    .languages-container {
      margin-bottom: 20px;
    }

    .action-cards {
      display: grid !important;
      grid-template-columns: repeat(2, 1fr) !important;
      gap: 8px !important;
      padding: 0 10px !important;
      margin: 5px 0 !important;
      flex-direction: unset !important;
      flex-wrap: unset !important;
      width: 100% !important;
    }

    .action-card {
      width: 100% !important;
      height: 100px !important;
      margin: 0 !important;
      
      .card-icon {
        font-size: 1.8rem;
        margin-bottom: 0.5rem;
      }

      span {
        font-size: 0.9rem;
      }
    }

    .social-section {
      padding-top: 30px;
      padding-bottom: 15px;
    }

    .social-links {
      margin-bottom: 15px;
      gap: 10px;
    }

    .scroll-indicator {
      margin-top: 15px;
      margin-bottom: 10px;
    }
  }

  .bottom-section {
    padding-top: 30px;
  }

  .status-badge {
    padding: 5px 10px;
    font-size: 0.8rem;
    margin: 5px auto;
  }

  .expertise-container {
    margin-top: 15px;
    padding: 0 15px;
    text-align: center;
    width: 100%;
    
    .expertise-text {
      font-size: 1.5rem !important;
      line-height: 1.4;
      display: block;
      width: 100%;
    }
  }

  .intro-content {
    .expertise-text {
      font-size: 1.5rem;
      padding: 0 10px;
      
      &::before {
        top: -5px;
        left: -5px;
        right: -5px;
        bottom: -5px;
      }
    }
  }
}

@media (max-height: 700px) {
  .intro {
    .intro-content {
      .intro-body {
        gap: 10px;
      }
    }

    .typed-text,
    .expertise-text,
    .language-text {
      font-size: 1.2rem !important;
    }

    .action-cards {
      margin: 10px 0;
      
      .action-card {
        height: 100px;
      }
    }

    .social-section {
      padding-top: 30px;
      padding-bottom: 15px;
    }

    .social-links {
      margin-top: 10px;
      padding: 10px 0;
    }
  }
}

@media (max-height: 500px) and (orientation: landscape) {
  .intro {
    padding-top: 60px;
    .intro-content {
      padding: 15px;
      
      .intro-body {
        gap: 10px;
      }
    }

    .action-cards {
      grid-template-columns: repeat(4, 1fr);
      gap: 5px;
    }

    .action-card {
      height: 70px;
    }

    .intro-text {
      max-width: 90%;
    }
  }
}

@media (max-width: 380px) {
  .intro {
    .social-links {
      gap: 15px;
      
      .social-link {
        width: 40px;
        height: 40px;
        font-size: 1.1rem;
      }
    }

    .action-cards {
      grid-template-columns: repeat(2, 1fr) !important;
      gap: 6px !important;
    }

    .action-card {
      height: 90px !important;
    }

    .intro-text {
      font-size: 0.9rem;
    }

    .expertise-text {
      font-size: 1.2rem !important;
    }
  }
}

/* Add this if you want a nice shadow effect on hover */
.social-link:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.intro-content {
  padding-top: 80px;
}

.status-badge {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  padding: 10px 20px;
  border-radius: 30px;
  color: white;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  margin: 0 auto;
  width: fit-content;
}

.pulse {
  width: 12px;
  height: 12px;
  background-color: #4CAF50;
  border-radius: 50%;
  position: relative;
  display: inline-block;
}

.pulse::before {
  content: '';
  position: absolute;
  border: 2px solid #4CAF50;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: pulse 2s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
}

@media (max-width: 768px) {
  .social-link .ico-circle {
    width: 32px;
    height: 32px;
    font-size: 1rem;
  }

  .status-badge {
    padding: 5px 10px;
    font-size: 0.8rem;
    margin: 5px auto;
  }
}

.expertise-container {
  margin: 0;
  padding: 5px;
  text-align: center;
  width: 100%;
  position: relative;
  z-index: 3;
  
  .expertise-text {
    font-size: 2rem;
    line-height: 1.4;
    display: block;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .expertise-container {
    .expertise-text {
      font-size: 1.5rem !important;
    }
  }
}

/* Fix media query syntax */
@media (max-width: 768px) {
  .intro {
    /* Use proper comment syntax */
    .social-links {
      gap: 15px;
    }
  }
}

/* Fix nested calc functions */
.some-element {
  width: calc(33.33% - 10px); /* Add spaces around operators */
}

.intro.fade-in {
  opacity: 1;
}

/* Add these new styles while keeping existing ones */
body, 
.intro, 
#home, 
.intro-content {
  background-color: #001529;
}

.gradient-overlay {
  background: linear-gradient(
    135deg,
    rgba(0, 21, 41, 0.97) 0%,
    rgba(0, 21, 41, 0.95) 30%,
    rgba(0, 21, 41, 0.92) 70%,
    rgba(0, 21, 41, 0.97) 100%
  );
}

.bottom-section,
.social-section,
.action-cards {
  background-color: transparent;
}

/* Add stars animation */
#intro {
  position: relative;
  overflow: hidden;
}

#intro-stars {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 1487px 822px #FFF, 702px 619px #FFF, 579px 76px #FFF, 881px 14px #FFF,
    1903px 741px #FFF, 1401px 1791px #FFF, 1316px 857px #FFF, 1628px 1376px #FFF,
    1487px 822px #FFF, 702px 619px #FFF, 579px 76px #FFF, 881px 14px #FFF;
  animation: animIntroStar 50s linear infinite;
  opacity: 0.8;
}

#intro-stars2 {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 592px 134px #FFF, 346px 741px #FFF, 1000px 1781px #FFF, 996px 728px #FFF,
    1085px 1462px #FFF, 526px 1128px #FFF, 1951px 557px #FFF, 673px 92px #FFF;
  animation: animIntroStar 100s linear infinite;
  opacity: 0.9;
}

#intro-stars3 {
  width: 4px;
  height: 4px;
  background: transparent;
  box-shadow: 222px 946px #FFF, 408px 926px #FFF, 1608px 1741px #FFF, 1977px 1436px #FFF,
    713px 1152px #FFF, 139px 39px #FFF, 497px 1590px #FFF, 1808px 1652px #FFF;
  animation: animIntroStar 150s linear infinite;
  opacity: 1;
}

@keyframes animIntroStar {
  from {
    transform: translateY(1000px);
  }
  to {
    transform: translateY(-1000px);
  }
}

/* Add these elements to maintain proper z-index layering */
.intro-content {
  position: relative;
  z-index: 3;
}

/* Ensure stars are behind content */
#intro-stars,
#intro-stars2,
#intro-stars3 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  pointer-events: none;
}

/* Update z-index hierarchy */
.gradient-overlay {
  z-index: 1;
}

.floating-shapes {
  z-index: 2;
}

.intro-content {
  position: relative;
  z-index: 3;
}

/* Increase star visibility */
#intro-stars {
  width: 2px;
  height: 2px;
  box-shadow: 1487px 822px #FFF, 702px 619px #FFF, 579px 76px #FFF, 881px 14px #FFF,
    1903px 741px #FFF, 1401px 1791px #FFF, 1316px 857px #FFF, 1628px 1376px #FFF,
    1487px 822px #FFF, 702px 619px #FFF, 579px 76px #FFF, 881px 14px #FFF;
  opacity: 0.8;
}

#intro-stars2 {
  width: 3px;
  height: 3px;
  opacity: 0.9;
}

#intro-stars3 {
  width: 4px;
  height: 4px;
  opacity: 1;
}

/* Slow down animation slightly for better effect */
@keyframes animIntroStar {
  from {
    transform: translateY(1000px);
  }
  to {
    transform: translateY(-1000px);
  }
}

/*
  --------------------------------------------------
  Cinematic & Architectural Styles (Blueprint + Letterbox)
  --------------------------------------------------
*/

/* Simplify text styles while keeping background effects */
.intro-text {
  position: relative;
  padding: 8px;
  margin: 0;
  max-width: 800px;
  text-align: center;
  line-height: 1.4;
  font-size: 1.2rem;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 12px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
}

/* Simplify text highlight styles */
.highlight-text {
  font-weight: 600;
  color: #ffffff;
}

.specialty-text {
  font-weight: 500;
  color: #ffffff;
}

.experience-badge {
  display: inline-block;
  margin: 0 5px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  padding: 0.2rem 0.6rem;
  border-radius: 20px;
  font-weight: 500;
}

.tech-highlight {
  color: #ffffff;
  font-weight: 500;
}

/* Remove complex animations but keep fade in */
.cinematic-fade {
  opacity: 0;
  animation: fadeIn 1s forwards ease-in-out;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

/* ---------- Architectural 'Blueprint' Grid ---------- */
.intro-text::before {
  content: "";
  position: absolute;
  inset: 0;
  pointer-events: none;
  background:
    repeating-linear-gradient(
      0deg,
      rgba(255,255,255,0.08) 0,
      rgba(255,255,255,0.08) 1px,
      transparent 1px,
      transparent 30px
    ),
    repeating-linear-gradient(
      90deg,
      rgba(255,255,255,0.08) 0,
      rgba(255,255,255,0.08) 1px,
      transparent 1px,
      transparent 30px
    );
  animation: rotateGrid 45s linear infinite;
  z-index: -1;
}

@keyframes rotateGrid {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/*
  ======================
  Highlight Text Styles
  (Neutral, Subtle Colors)
  ======================
*/

/* Glitch effect for "Hi, I'm a Solution Architect" */
.glitch {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 700;
  color: #ffffff;
  animation: glitch 1.5s infinite;
}

@keyframes glitch {
  0% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(-2px, 2px);
  }
  40% {
    transform: translate(2px, -2px);
  }
  60% {
    transform: translate(-1px, 1px);
  }
  80% {
    transform: translate(1px, -1px);
  }
  100% {
    transform: translate(0, 0);
  }
}

/* Subtle cosmic glare for "Core Banking Solutions and Enterprise Architecture" */
.cosmic-glare {
  font-weight: 500;
  color: #ffffff;
  position: relative;
  animation: cosmicGlare 3s infinite alternate ease-in-out;
}

@keyframes cosmicGlare {
  0% {
    text-shadow: 0 0 3px #ffffff, 0 0 8px #ffffff;
  }
  100% {
    text-shadow: 0 0 6px #ffffff, 0 0 12px #ffffff;
  }
}

/* More subdued wave effect for the experience badge */
.wave-fx {
  background: linear-gradient(90deg, #4d4d4d, #7a7a7a);
  padding: 0.3rem 0.6rem;
  color: #ffffff;
  font-weight: 600;
  border-radius: 20px;
  animation: waveFx 2s infinite ease-in-out;
}

@keyframes waveFx {
  0%, 100% {
    letter-spacing: 1px;
  }
  50% {
    letter-spacing: 5px;
  }
}

/* Flicker effect for tech highlights */
.flicker {
  color: #ffffff;
  font-weight: 500;
  position: relative;
  animation: flicker 2s infinite;
}

@keyframes flicker {
  0% {
    opacity: 1;
    text-shadow: 0 0 3px rgba(255, 255, 255, 0.7);
  }
  10% {
    opacity: 0.9;
  }
  20% {
    opacity: 0.7;
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.6);
  }
  40% {
    opacity: 1;
    text-shadow: 0 0 2px rgba(255, 255, 255, 0.4);
  }
  60% {
    opacity: 0.8;
    text-shadow: 0 0 6px rgba(255, 255, 255, 0.8);
  }
  80% {
    opacity: 0.6;
    text-shadow: 0 0 4px rgba(255, 255, 255, 0.8);
  }
  100% {
    opacity: 1;
    text-shadow: 0 0 4px rgba(255, 255, 255, 0.5);
  }
}

/* Experience badge styling */
.experience-badge {
  display: inline-block;
  margin: 0 5px;
  background: linear-gradient(45deg, #498faf, #2e348a);
  color: #fff;
  padding: 0.2rem 0.6rem;
  border-radius: 20px;
  font-weight: 600;
}

/*
  --------------------
  Cinematic Letterbox
  --------------------
*/
.letterbox::before,
.letterbox::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 50%;
  left: 0;
  background: #000;
  z-index: 9999; /* Covers the text momentarily */
  animation-fill-mode: forwards;
}

/* Top bar slides up */
.letterbox::before {
  top: 0;
  animation: letterboxOpenTop 1.5s 0.5s ease-in-out forwards;
}

@keyframes letterboxOpenTop {
  0%   { transform: translateY(0); }
  100% { transform: translateY(-100%); }
}

/* Bottom bar slides down */
.letterbox::after {
  bottom: 0;
  animation: letterboxOpenBottom 1.5s 0.5s ease-in-out forwards;
}

@keyframes letterboxOpenBottom {
  0%   { transform: translateY(0); }
  100% { transform: translateY(100%); }
}

/* Updated Footer styles */
.footer {
  position: relative; /* Changed from fixed */
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 45px;
  background: rgba(0, 0, 0, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 0.85rem;
  padding: 10px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.5);
  margin-top: 40px; /* Add space between content and footer */
}

.footer a {
  color: #fff;
  text-decoration: none;
  opacity: 0.9;
  padding: 2px 4px;
}

.footer span {
  color: #fff;
  opacity: 0.7;
}

/* Container styles to ensure proper layout */
.article-section {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.article-content {
  flex: 1;
  padding-bottom: 20px;
}

/* Mobile adjustments */
@media screen and (max-width: 768px) {
  .footer {
    min-height: 40px;
    font-size: 0.75rem;
    padding: 8px;
    gap: 6px;
    margin-top: 30px;
  }
}

/* Extra small devices */
@media screen and (max-width: 380px) {
  .footer {
    min-height: 35px;
    font-size: 0.7rem;
    padding: 6px;
    gap: 4px;
    margin-top: 25px;
  }
}

/* Landscape mode */
@media screen and (max-height: 500px) and (orientation: landscape) {
  .footer {
    height: 30px;
    font-size: 0.7rem;
    padding: 2px 5px;
  }
}