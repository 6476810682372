#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 9999;
  transition: opacity 0.3s;
}

#preloader.hidden {
  opacity: 0;
  pointer-events: none;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #0078ff;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0% { 
    transform: rotate(0deg); 
  }
  100% { 
    transform: rotate(360deg); 
  }
}

@media (prefers-color-scheme: dark) {
  #preloader {
    background-color: #1a1a1a;
  }
  
  .spinner {
    border: 3px solid #333;
    border-top: 3px solid #0078ff;
  }
} 