.skills-section {
  padding-top: 4rem;
  position: relative;
  z-index: 2;
  min-height: 100vh;
}

.box-shadow-full {
  background: rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 3rem 2rem;
  margin-bottom: 3rem;
}

.title-left {
  margin-bottom: 2rem;
  font-weight: 700;
  position: relative;
  text-align: center;
}

.skills-container {
  display: flex;
  align-items: flex-start;
  margin-top: 2rem;
}

.skill-mf {
  background: rgba(255, 255, 255, 0.03);
  padding: 1.5rem;
  border-radius: 10px;
  height: 100%;
}

.col-md-6 {
  padding: 0 15px;
}

.skill-title {
  color: rgba(255, 255, 255, 0.95) !important;
  font-size: 1rem;
  margin-bottom: 0.8rem;
  display: block;
  font-weight: 500;
  letter-spacing: 0.3px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.percentage {
  color: #00b0ff !important;
  font-weight: 600;
  float: right;
}

.progress {
  height: 0.7rem;
  margin-bottom: 1.5rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  overflow: hidden;
  clear: both;
}

.progress-bar {
  background: linear-gradient(to right, #00b0ff, #0078ff);
  border-radius: 15px;
  transition: width 0.1s ease-in-out;
  color: white;
  font-weight: bold;
  text-align: center;
  line-height: 1.2;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.4);
}

/* Ensure equal height columns */
@media (min-width: 768px) {
  .skills-container {
    min-height: 100%;
  }
  
  .col-md-6 {
    display: flex;
    flex-direction: column;
  }
  
  .skill-mf {
    flex: 1;
  }
} 

/* Mobile-specific adjustments */
@media (max-width: 768px) {
  .skills-section {
    padding-top: 2rem;
  }

  .box-shadow-full {
    margin-top: 1rem;
    padding: 1.5rem 1rem;
  }

  .title-left {
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
  }

  .skills-container {
    margin-top: 1rem;
  }

  .col-md-6 {
    padding: 0 10px;
    margin-bottom: 1rem;
  }
}

/* Additional iOS safe area support */
@supports (-webkit-touch-callout: none) {
  .skills-section {
    padding-top: calc(2rem + env(safe-area-inset-top));
  }
} 