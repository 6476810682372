.contact-section {
  padding-top: 6rem;
  position: relative;
  z-index: 2;
  min-height: 100vh;
}

/* Form container styling */
.box-shadow-full {
  background: rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 3rem 2rem;
  margin-bottom: 3rem;
}

/* Form inputs styling */
.form-control {
  background: rgba(255, 255, 255, 0.05) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  color: #ffffff !important;
  backdrop-filter: blur(5px);
  transition: all 0.3s ease;
}

.form-control:focus {
  background: rgba(255, 255, 255, 0.1) !important;
  border-color: rgba(0, 176, 255, 0.5) !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 176, 255, 0.25);
}

.form-control::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

/* Text colors */
h4, .lead {
  color: #ffffff;
}

.lead {
  opacity: 0.9;
}

/* Button styling */
.button-a {
  background: orange !important;
  border: none;
  padding: 0.8rem 2rem;
  border-radius: 30px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.button-a:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(255, 165, 0, 0.3);
}

/* Social icons */
.socials .ico-circle {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #ffffff;
  transition: all 0.3s ease;
}

.socials .ico-circle:hover {
  background: orange;
  border-color: orange;
  transform: translateY(-3px);
}

/* Validation messages */
.validation {
  color: rgba(255, 255, 255, 0.8);
  margin-top: 0.5rem;
  font-size: 0.9rem;
}

/* Success message */
#sendmessage {
  color: #28a745;
  background: rgba(40, 167, 69, 0.1);
  border: 1px solid rgba(40, 167, 69, 0.2);
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  display: none;
}

/* Error message */
#errormessage {
  color: #dc3545;
  background: rgba(220, 53, 69, 0.1);
  border: 1px solid rgba(220, 53, 69, 0.2);
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  display: none;
} 